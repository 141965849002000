import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class MarkdownService {
  constructor(private http: HttpClient) { }

  public getMarkdownByPath(path: string): Observable<string> {
    return this.http.get<string>(path, { responseType: 'text' as 'json' });
  }
}
